@font-face {
  font-family: "opensans-bold";
  src: url("fonts/open-sans/OpenSans-Bold.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans-Regular";
  src: url("fonts/open-sans/OpenSans-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans-Light";
  src: url("fonts/open-sans/OpenSans-Light.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Addington-CF";
  src: url("fonts/open-sans/Addington-CF/Addington.CF/AddingtonCF-Medium.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lufga-Regular";
  src: url("fonts/lufga/Lufga-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lufga";
  src: url("fonts/lufga/Lufga-Light.ttf");
  font-weight: light;
  font-style: normal;
}

@font-face {
  font-family: "Lufga-Bold";
  src: url("fonts/lufga/Lufga-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}

.unselectable {
  -webkit-user-select: none; /* Safari, Chrome */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE */
  user-select: none; /* CSS3 */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mydiv {
  display: flex;
  flex-direction: column;
  background-size: cover !important;
  -webkit-background-size: cover !important;
}

.container {
  margin-top: 10rem;
}

.nav-icons path {
  stroke: white !important;
  stroke-width: 2px;
}
.nav-icons {
  font-size: 2rem;
  /* display: none;
	visibility: hidden; */
}

header {
  background: transparent;
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 900px;
  max-height: 20rem;
  opacity: 95%;
  text-align: center;
  overflow: hidden;
  vertical-align: middle;
}

body {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)
    no-repeat;
  background-size: 500% 100%;
  width: fit-content;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.headline {
  color: white;
  font-size: 5rem;
  font-family: "Lufga-Bold";
  margin-top: 1em;
}

.name-h2 {
  position: relative;
  font-size: 1.5rem;
  color: #282c34;
  margin: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-family: "Lufga-Bold";
}

.network {
  display: flex;
  align-content: center;
  padding-top: 0rem;
  padding-bottom: 0rem;
  margin-bottom: 0rem;
}

.headerNetwork {
  padding-top: 5rem;
}

.about {
  position: relative;
  display: block;
  overflow: hidden;
  height: 100%;
  min-height: max-content;
  width: 100%;
  background-color: #282c34;
  background-size: cover !important;
  -webkit-background-size: cover !important;
  opacity: 100%;
  text-align: left;
  padding-bottom: 8em;
  border-bottom-style: solid;
  background-color: #282c34;
  border-top-width: 20px;
  border-top-style: outset;
  border-top-color: #fefee7;
  border-top-width: 0em;
  border-bottom-width: 0em;
  border-bottom-style: solid;
  border-bottom-color: #a4d0c4;
  padding-top: 2em;
}

.tech {
  background: white;
  background-size: cover !important;
  -webkit-background-size: cover !important;
  opacity: 100%;
  text-align: center;
  color: #282c34;
  overflow: hidden;
}

.footerNetwork {
  display: flex;
  align-content: center;
  padding-top: 2rem;
  margin-bottom: -4rem;
}

.nav-link {
  margin-right: 1rem;
  font-family: "Lufga-Bold";
}

.ml-auto {
  color: white;
  float: right;
  position: sticky;
  background-color: #282c34;
}

#hamburger,
#hamburger:focus,
#hamburger:active,
#hamburger:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

#hamburger:hover,
#hamburger:focus {
  background-color: #505868;
}

.logo {
  height: 2em;
}

.mini {
  display: none;
  visibility: hidden;
}

.profilePic {
  display: flex;
  margin: 0 auto;
  margin-top: 0em;
  border-style: solid;
  border-color: transparent;
  border-width: 0em;
  padding: 1.5em;
  justify-content: center;
  overflow: hidden;
}

.photoAnimation {
  width: 20em;
  height: 15.5em;
  margin-top: 2.5rem;
  background: white;
  box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2);
  animation: morphing 15s ease infinite;
  overflow: hidden;
}

.profileAnimation:hover {
  animation-play-state: paused;
}

@keyframes morphing {
  0% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2);
  }
  25% {
    border-radius: 58% 42% 75% 25% / 76% 46% 54% 24%;
  }
  50% {
    border-radius: 50% 50% 33% 67% / 55% 27% 73% 45%;
    box-shadow: -10px -5px 50px rgba(0, 0, 0, 0.2);
  }
  75% {
    border-radius: 33% 67% 58% 42% / 63% 68% 32% 37%;
  }
  100% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2);
  }
}

.copyright {
  color: white;
  font-family: "Lufga-Regular";
  padding-top: 3rem;
  font-size: 1rem;
  padding-bottom: 2em;
  text-align: center;
  margin: auto;
}

.copyright em {
  font-family: "Lufga-Bold";
  font-style: normal;
}

.about p {
  display: flex;
  justify-content: left;
}

.name-h2 em {
  color: white;
  font-style: normal;
  font-weight: bolder;
}

.name-headline em {
  color: #282c34;
  opacity: 100%;
  font-style: normal;
}
.headline em {
  color: #282c34;
  opacity: 100%;
  font-style: normal;
}

.flex-container {
  display: flex;
  justify-content: center;
  margin-top: 3em;
  margin-left: 10%;
  margin-right: 5%;
}

.flex-left {
  width: 30%;
  padding-right: 0rem;
  margin-bottom: 0em;
  margin-right: 3rem;
}

.flex-right {
  width: 70%;
  margin-left: 0rem;
  margin-right: 0em;
  margin-top: 5em;
  margin-bottom: 0em;
  font-size: 1rem;
}

#about-top {
  background: white;
  color: #282c34;
  border-bottom: #72c4b9;
  border-right: white;
  border-left: #a4d0c4;
  border-top: white;
  border-bottom-width: 0rem;
  border-right-width: 0rem;
  border-left-width: 0rem;
  border-top-width: 0rem;
  border-top-left-radius: 5rem;
  border-top-right-radius: 5rem;
  border-bottom-right-radius: 5rem;
  border-bottom-left-radius: 5rem;
  font-weight: bolder;
  font-family: "lufga";
  padding: 2rem;
  padding-right: 8rem;
  margin-right: -10rem;
  margin-left: 5em;
  margin-top: 2rem;
  margin-bottom: 3rem;
  border-style: outset;
  text-indent: 1rem;
  text-align: left;
}

#about-middle {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  color: white;
  border-bottom: #72c4b9;
  border-right: white;
  border-left: #a4d0c4;
  border-top: white;
  border-bottom-width: 0rem;
  border-right-width: 0rem;
  border-left-width: 0rem;
  border-top-width: 0rem;
  border-top-left-radius: 5rem;
  border-top-right-radius: 5rem;
  border-bottom-right-radius: 5rem;
  border-bottom-left-radius: 5rem;
  font-weight: bolder;
  font-family: "lufga";
  padding: 2rem;
  padding-right: 8rem;
  margin-right: -10rem;
  margin-left: 2.5em;
  margin-bottom: 3rem;
  border-style: outset;
  text-indent: 1rem;
  text-align: left;
}

#about-bottom {
  background: white;
  color: #282c34;
  border-bottom: #72c4b9;
  border-right: white;
  border-left: #a4d0c4;
  border-top: white;
  border-bottom-width: 0rem;
  border-right-width: 0rem;
  border-left-width: 0rem;
  border-top-width: 0rem;
  border-top-left-radius: 5rem;
  border-top-right-radius: 5rem;
  border-bottom-right-radius: 5rem;
  border-bottom-left-radius: 5rem;
  font-weight: bolder;
  font-family: "lufga";
  padding: 2rem;
  padding-right: 8rem;
  margin-right: -10rem;
  border-style: outset;
  text-indent: 1rem;
  text-align: left;
}

.contact {
  display: block;
  color: white;
  font-family: "Lufga-Bold";
  margin-bottom: 1em;
}

#contact-info {
  margin-top: 4rem;
  margin-right: -4rem;
}

.flex-container2 {
  margin: auto;
  width: auto;
}

.flex-left2 {
  font-family: "Lufga-Bold";
  font-size: 4em;
  color: #282c34;
  margin: 0em;
  margin-top: 2em;
  width: auto;
}

.flex-right2 {
  width: auto;
  font-size: 1.3em;
  font-family: "Lufga-Regular";
  font-variant: normal;
  color: white;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  border-radius: 5rem;
  padding: 1.2rem;
  margin: 3em;
}

.tech-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  padding-top: 1rem;
  margin: auto;
}

.aboutIcons {
  color: white;
  margin-bottom: 0rem;
  font-size: 1.5rem;
  margin-bottom: 0.1em;
}

.flex-left2 em {
  font-style: normal;
  padding-bottom: 5px;
  border-bottom: 5px solid #23d5ab;
  line-height: 48px;
}

#card {
  margin-bottom: 2.5em;
  background-color: transparent;
}

#cardTitle {
  font-family: "Lufga-Bold";
  font-variant: small-caps;
  font-size: 1.5rem;
  color: #282c34;
}

#cardText {
  font-family: "lufga";
  color: #9a9c9f;
  line-height: 1.25em;
  border-radius: 5rem;
  font-size: 1.25em;
}

#cardImage {
  display: flex;
  width: 8em;
  margin-left: auto;
  margin-right: auto;
}

.flex-container3 {
  padding-top: 2rem;
  padding-bottom: 1rem;
  background-color: #282c34;
  height: auto;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

a:link {
  color: white;
  text-decoration: none;
}

a:hover {
  color: white;
  text-decoration: none;
}

a:visited {
  color: white;
  text-decoration: none;
}
.contact a:hover {
  color: #23d5ab;
}

ul {
  display: flex;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-right: 2rem;
}

ul li {
  list-style: none;
}

ul li a {
  width: 60px;
  height: 60px;
  background-color: transparent;
  text-align: center;
  line-height: 80px;
  font-size: 35px;
  margin: 0 20px;
  display: block;
  border-radius: 0%;
  position: relative;
  overflow: visible;
  border: 3px solid transparent;
  z-index: 1;
}

#footer ul li a {
  border: 3px solid white;
  border-radius: 50%;
}

ul li a .icon {
  position: relative;
  color: white;
  transition: 0.5s;
  z-index: 3;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 0.3em;
  font-size: 1em;
  background-color: transparent;
  cursor: pointer;
}

.footerNetwork ul li a .icon {
  position: relative;
  color: white;
  transition: 0.5s;
  z-index: 3;
  margin-top: 0.5em;
  font-size: 1em;
  background-color: transparent;
  cursor: pointer;
}

/* ul li a:hover .icon {
	color: white;
	transform: rotateY(360deg);
  } */

ul li a:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  transition: 0.5s;
  z-index: 2;
}

ul li a:hover:before {
  top: 0;
}

ul li:nth-child(1) a:before {
  /* background: #a4d0c4;
	opacity: 100%; */
}

ul li:nth-child(2) a:before {
  /* background: #a4d0c4;
	opacity: 100%; */
}

ul li:nth-child(3) a:before {
  /* background: #a4d0c4;
	opacity: 100%; */
}

ul li:nth-child(4) a:before {
  /* background: #dd4b39; */
}

@media (max-width: 750px) {
  body {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 1000% 40%;
    animation: gradient 15s ease infinite;
  }

  .logo {
    display: none;
    visibility: hidden;
  }

  .mini {
    display: block;
    visibility: visible;
    float: left;
    height: 1.5em;
  }

  .name-h2 {
    font-size: 1.1rem;
  }

  .ml-auto {
    font-family: "OpenSans-Bold";
    color: white;
    background-color: #282c34;
  }

  .nav-link {
    font-size: larger;
    line-height: 2em;
    text-align: center;
    margin: 1rem;
  }

  #nav-links {
    display: block;
    justify-content: center;
    font-size: 2rem;
    height: 90vh;
    margin: auto;
    margin-top: 4em;
    width: 100%;
  }
  .nav-icons {
    font-size: 3rem;
    margin-bottom: 1em;
    stroke: white !important;
  }

  .headline {
    margin-top: 2rem;
  }

  .flex-container {
    flex-direction: column;
    margin-left: 4rem;
    margin-top: 2rem;
    margin-right: 4rem;
    margin-bottom: 0rem;
    height: fit-content;
    align-content: center;
  }

  .flex-container2 {
    flex-wrap: wrap;
  }

  .flex-left2 {
    justify-content: center;
    width: 100%;
    margin: auto;
    margin-top: 2em;
    margin-bottom: 1em;
    font-size: 2.5em;
  }

  .flex-right2 {
    font-size: 1em;
  }

  .flex-left {
    margin: auto;
    width: auto;
  }

  .photoAnimation {
    margin-left: 0em;
    margin-right: 00em;
    width: 100%;
  }

  .flex-right {
    width: auto;
    margin: auto;
    margin-top: 2rem;
  }

  .aboutSection {
    margin-right: -6em;
    justify-content: center;
    margin: 0rem;
    padding: 0em;
  }

  header {
    height: fit-content;
    min-height: 100vh;
    background-size: cover !important;
    -webkit-background-size: cover !important;
  }

  .name-headline {
    margin: 0rem;
    font-size: 3rem;
  }

  ul li a .icon {
    font-size: 35px;
  }

  .about {
    display: block;
    padding-top: 5rem;
    display: flex;
  }

  #cardText {
    font-size: 1em;
    margin-left: 0rem;
    margin-right: 0rem;
  }

  #cardImage {
    width: 8em;
  }

  #about-top,
  #about-bottom {
    margin-left: -2em;
    line-height: 1.25em;
    margin-right: -11rem;
    padding-left: 2rem;
    text-indent: 0rem;
    padding-right: 8.5rem;
    text-align: right;
  }
  #about-middle {
    line-height: 1.25em;
    margin-right: -0em;
    padding-left: 7.5rem;
    text-indent: 0rem;
    padding-right: 2rem;
    margin-left: -10rem;
    text-align: left;
  }

  #contact-info {
    margin-top: 3em;
    margin-left: 0.5em;
    margin-right: 0.5em;
    margin-bottom: -1em;
    display: flex;
    flex-flow: wrap;
    justify-content: center;
  }

  .contact {
    margin: 1em;
    font-size: smaller;
  }
}
